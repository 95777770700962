import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { CRMContext } from "../../context/CRMContext";
import getUserData from '../../utils/getDataUser';

const Navegacion = () => {
  const [auth, guardarAuth] = useContext(CRMContext);

  if (!auth.auth) return null;
  const token = getUserData();
  return (
    <aside className="sidebar col-3">
      <>
        <h3>Gestor de Usuario EHE</h3>
        <h4>Bienvenido: {token.first_name.toUpperCase()}</h4>
        {token.first_name !== undefined ? (
          <>
            <h5> {token.id_rol === 3 && "Rol: Coordinador General DPE"}</h5>
            <h5> {token.id_rol === 4 && "Rol: Asistente DPE"}</h5>
            <h5> {token.id_rol === 5 && "Rol: Coordinador Municipal"}</h5>
            <h5> {token.id_rol === 6 && "Rol: Supervisor Municipal"}</h5>
            <h5>Código Partido: {token.codpart}</h5> </>) : null}

        <nav className="navegacion">
          <Link to={"/"} className="clientes">
            Usuarios
          </Link>
          {/*   <Link to={"/productos"} className="productos">
          Productos
        </Link>
        <Link to={"/pedidos"} className="pedidos">
          Pedidos
        </Link> */}
          <Link to={"/personal"} className="clientes">
            ver/exportar usuarios
          </Link>
        </nav>
      </>
    </aside>
  );
};

export default Navegacion;
