import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import clienteAxios from "../../config/axios";

function Cliente({ cliente }) {
  // extraer los valores



  const { id_user, first_name, last_name, img, email, active } = cliente;
  // console.log(cliente);
  // Eliminar cliente
  const [estado, guardarEstado] = useState(active);
  const eliminarCliente = (id_user) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Deshabilitarlo implica desasignar todas las viviendas que tenia en su poder, y quitarle el login a la aplicacion mobile",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        // Llamado a axios
        clienteAxios.delete(`/usuarios/${id_user}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then((res) => {
          Swal.fire("Eliminado", res.data.mensaje, "success");
        });
      }
      guardarEstado(false)
      // window.location.replace('/');
    });
  };

  return (
    <li className="cliente">
      <div className="info-cliente">
        <p className="first_name">
          <label className="label2"> Apellido-Nombre: </label>{last_name.toUpperCase()} -   {first_name.toUpperCase()}
        </p>
        {/* <p className="img">{img}</p> */}
        <p > <label className="label2">Usuario:</label>   {email}</p>
        <p>
          <label className="label2">Estado:</label>
          {estado === true ? (

            <label className="verde">activo</label>

          ) : (

            <label className="rojo">Inactivo</label>

          )}
        </p>
      </div>
      <div className="acciones">
        <Link to={`/clientes/editar/${id_user}`} className="btn btn-celeste">
          <i className="fas fa-pen-alt" />
          Editar Usuario
        </Link>
        {/* 
        <Link to={`/pedidos/nuevo/${id_user}`} className="btn btn-amarillo">
          <i className="fas fa-plus" />
          Nuevo Pedido
        </Link> */}

        <button
          type="button"
          className="btn btn-rojo btn-eliminar"
          onClick={() => eliminarCliente(id_user)}
        >
          <i className="fas fa-times" />
          Deshabilitar Usuario
        </button>
      </div>
    </li>
  );
}
export default Cliente;
