import jwt_decode from "jwt-decode";
//const rol = 1
const getUserData = () => {
    //console.log(jwt_decode(localStorage.getItem('token')));
    //console.log(localStorage.getItem('token') !== "" && jwt_decode(localStorage.getItem('token')));
    return localStorage.getItem('token') !== "" && jwt_decode(localStorage.getItem('token'));
    /* if(!localStorage.getItem('token')) {
        // go to login route
      }
     */
    /* codpart: "623"
    email: "Prella@mail.com"
    exp: 1626971152
    first_name: "pablo"
    iat: 1626967552
    id_rol: 6
    id_user: 59 */

}
export default getUserData;