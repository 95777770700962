import React, { Fragment, useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import getUserData from '../../utils/getDataUser';

function EditarCliente(props) {
  // obtener el ID
  const id_user = props.match.params.id;
  const [auth, guardarAuth] = useContext(CRMContext);
  // cliente = state, datosCliente = funcion para guardar el state
  const [token, guardarToken] = useState('');

  useEffect(() => {
    (localStorage.getItem('token') === null) && localStorage.setItem("token", "")
    guardarToken(getUserData()
    )
  }, [auth]);
  const [cliente, datosCliente] = useState({
    nombre: "",
    apellido: "",
    empresa: "",
    codpart: "",
    id_rol: "",
    email: "",
    phone: "",
  });


  // Query a la API
  const consultarAPI = async () => {
    const clienteConsulta = await clienteAxios.get(
      `/usuarios/${id_user}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    //console.log(auth.token);
    //console.log("Aca veo");
    //console.log(clienteConsulta.data);
    // colocar en el state
    datosCliente(clienteConsulta.data);
  };

  // useEffect, cuando el componente carga
  useEffect(() => {
    consultarAPI();
  }, []);

  // leer los datos del formulario
  const actualizarState = (e) => {
    // Almacenar lo que el usuario escribe en el state
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    datosCliente({
      // obtener una copia del state actual
      ...cliente,
      [name]: value,
    });
  };

  // Envia una petición por axios para actualizar el cliente
  const actualizarCliente = (e) => {
    e.preventDefault();

    // enviar petición por axios
    clienteAxios.put(`/usuarios/${id_user}`, cliente, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      //console.log(res.data);
      // validar si hay errores de mongo
      //  if (res.data.code === 11000) {
      if (res.data.rowCount[0] === 1) {
        Swal.fire({
          type: "error",
          title: "Hubo un error",
          text: "Ese Usuario ya esta registrado",
        });
      } else {
        Swal.fire("Correcto", "Se actualizó Correctamente", "success");
      }

      // redireccionar
      props.history.push("/");
    });
  };

  // Validar el formulario
  const validarCliente = () => {
    // Destructuring
    const { first_name, last_name, email, password, active } = cliente;

    // revisar que las propiedades del state tengan contenido
    let valido = !first_name || !last_name || !email || !password || !active;

    // return true o false
    return valido;
  };
  //console.log(cliente);
  return (
    <Fragment>
      <h2>Editar Usuario</h2>

      <form onSubmit={actualizarCliente}>
        <legend>Completar todos los campos</legend>
        <div className="campo">
          <label>Nombre:</label>
          <input
            type="text"
            placeholder="Nombre User"
            name="first_name"
            onChange={actualizarState}
            value={cliente.first_name}
          />
        </div>

        <div className="campo">
          <label>Apellido:</label>
          <input
            type="text"
            placeholder="Apellido"
            name="last_name"
            onChange={actualizarState}
            value={cliente.last_name}
          />
        </div>

        <div className="campo">
          <label>Teléfono:</label>
          <input
            type="number"
            placeholder="Teléfono"
            name="phone"
            onChange={actualizarState}
            value={cliente.phone}
          />
        </div>

        {(token.id_rol === 3) &&
          <div className="campo">
            <label>Código Partido</label>
            <select className="seleccion" name="codpart" onChange={actualizarState} >
              <option value="007">007-Adolfo Alsina</option>
              <option value="014">014-Adolfo Gonzales Chaves</option>
              <option value="021">021-Alberti</option>
              <option value="028">028-Almirante Brown</option>
              <option value="077">077-Arrecifes</option>
              <option value="035">035-Avellaneda</option>
              <option value="042">042-Ayacucho</option>
              <option value="049">049-Azul</option>
              <option value="056">056-Bahía Blanca</option>
              <option value="063">063-Balcarce</option>
              <option value="070">070-Baradero</option>
              <option value="084">084-Benito Juárez</option>
              <option value="091">091-Berazategui</option>
              <option value="098">098-Berisso</option>
              <option value="105">105-Bolívar</option>
              <option value="112">112-Bragado</option>
              <option value="119">119-Brandsen</option>
              <option value="126">126-Campana</option>
              <option value="134">134-Cañuelas</option>
              <option value="140">140-Capitán Sarmiento</option>
              <option value="147">147-Carlos Casares</option>
              <option value="154">154-Carlos Tejedor</option>
              <option value="161">161-Carmen de Areco</option>
              <option value="168">168-Castelli</option>
              <option value="210">210-Chacabuco</option>
              <option value="218">218-Chascomús</option>
              <option value="224">224-Chivilcoy</option>
              <option value="175">175-Colón</option>
              <option value="182">182-Coronel de Marina L. Rosales</option>
              <option value="189">189-Coronel Dorrego</option>
              <option value="196">196-Coronel Pringles</option>
              <option value="203">203-Coronel Suárez</option>
              <option value="231">231-Daireaux</option>
              <option value="238">238-Dolores</option>
              <option value="245">245-Ensenada</option>
              <option value="252">252-Escobar</option>
              <option value="260">260-Esteban Echeverría</option>
              <option value="266">266-Exaltación de la Cruz</option>
              <option value="270">270-Ezeiza</option>
              <option value="274">274-Florencio Varela</option>
              <option value="277">277-Florentino Ameghino</option>
              <option value="280">280-General Alvarado</option>
              <option value="287">287-General Alvear</option>
              <option value="294">294-General Arenales</option>
              <option value="301">301-General Belgrano</option>
              <option value="308">308-General Guido</option>
              <option value="315">315-General Juan Madariaga</option>
              <option value="322">322-General La Madrid</option>
              <option value="329">329-General Las Heras</option>
              <option value="336">336-General Lavalle</option>
              <option value="343">343-General Paz</option>
              <option value="351">351-General Pinto</option>
              <option value="357">357-General Pueyrredon</option>
              <option value="364">364-General Rodríguez</option>
              <option value="371">371-General San Martín</option>
              <option value="385">385-General Viamonte</option>
              <option value="392">392-General Villegas</option>
              <option value="399">399-Guaminí</option>
              <option value="406">406-Hipólito Yrigoyen</option>
              <option value="408">408-Hurlingham</option>
              <option value="410">410-Ituzaingó</option>
              <option value="412">412-José C. Paz</option>
              <option value="413">413-Junín</option>
              <option value="420">420-La Costa</option>
              <option value="427">427-La Matanza</option>
              <option value="441">441-La Plata</option>
              <option value="434">434-Lanús</option>
              <option value="448">448-Laprida</option>
              <option value="455">455-Las Flores</option>
              <option value="462">462-Leandro N. Alem</option>
              <option value="466">466-Lezama</option>
              <option value="469">469-Lincoln</option>
              <option value="476">476-Lobería</option>
              <option value="483">483-Lobos</option>
              <option value="490">490-Lomas de Zamora</option>
              <option value="497">497-Luján</option>
              <option value="505">505-Magdalena</option>
              <option value="511">511-Maipú</option>
              <option value="515">515-Malvinas Argentinas</option>
              <option value="518">518-Mar Chiquita</option>
              <option value="525">525-Marcos Paz</option>
              <option value="532">532-Mercedes</option>
              <option value="539">539-Merlo</option>
              <option value="547">547-Monte</option>
              <option value="553">553-Monte Hermoso</option>
              <option value="560">560-Moreno</option>
              <option value="568">568-Morón</option>
              <option value="574">574-Navarro</option>
              <option value="581">581-Necochea</option>
              <option value="588">588-9 de Julio</option>
              <option value="595">595-Olavarría</option>
              <option value="602">602-Patagones</option>
              <option value="609">609-Pehuajó</option>
              <option value="616">616-Pellegrini</option>
              <option value="623">623-Pergamino</option>
              <option value="630">630-Pila</option>
              <option value="638">638-Pilar</option>
              <option value="644">644-Pinamar</option>
              <option value="648">648-Presidente Perón</option>
              <option value="651">651-Puan</option>
              <option value="655">655-Punta Indio</option>
              <option value="658">658-Quilmes</option>
              <option value="665">665-Ramallo</option>
              <option value="672">672-Rauch</option>
              <option value="679">679-Rivadavia</option>
              <option value="686">686-Rojas</option>
              <option value="693">693-Roque Pérez</option>
              <option value="700">700-Saavedra</option>
              <option value="707">707-Saladillo</option>
              <option value="721">721-Salliqueló</option>
              <option value="714">714-Salto</option>
              <option value="728">728-San Andrés de Giles</option>
              <option value="735">735-San Antonio de Areco</option>
              <option value="742">742-San Cayetano</option>
              <option value="749">749-San Fernando</option>
              <option value="756">756-San Isidro</option>
              <option value="760">760-San Miguel</option>
              <option value="763">763-San Nicolás</option>
              <option value="770">770-San Pedro</option>
              <option value="778">778-San Vicente</option>
              <option value="784">784-Suipacha</option>
              <option value="791">791-Tandil</option>
              <option value="798">798-Tapalqué</option>
              <option value="805">805-Tigre</option>
              <option value="812">812-Tordillo</option>
              <option value="819">819-Tornquist</option>
              <option value="826">826-Trenque Lauquen</option>
              <option value="833">833-Tres Arroyos</option>
              <option value="840">840-Tres de Febrero</option>
              <option value="847">847-Tres Lomas</option>
              <option value="854">854-25 de Mayo</option>
              <option value="861">861-Vicente López</option>
              <option value="868">868-Villa Gesell</option>
              <option value="875">875-Villarino</option>
              <option value="882">882-Zárate</option>
              <option value="999">999-Capacitacion</option>
            </select>
          </div>}
        <div className="campo">
          <label>Contraseña:</label>
          <input
            type="password"
            placeholder="Contraseña"
            name="password"
            onChange={actualizarState}
            value={cliente.password}
          />
        </div>
        <div className="campo">
          <label>Rol del usuario</label>
          <select className="seleccion" name="id_rol" onChange={actualizarState} required>
            <option value="">-- Seleccionar rol --</option>


            {(token.id_rol === 3 || token.id_rol === 5 || token.id_rol === 6) && <option value="2" >Encuestador/a Municipal</option>}
            {(token.id_rol === 3) && <option value="3" >Coordinador/a General DPE</option>}
            {(token.id_rol === 3) && <option value="4" >Asistente DPE</option>}
            {(token.id_rol === 3) && <option value="7" >Analista DPE</option>}
            {(token.id_rol === 3) && <option value="5"  >Coordinador/a Municipal</option>}
            {(token.id_rol === 3 || token.id_rol === 5) && <option value="6" >Supervisor/a Municipal</option>}

          </select>
        </div>


        <div className="campo">
          <label>Email:</label>
          <input
            type="email"
            placeholder="Email User"
            name="email"
            onChange={actualizarState}
            value={cliente.email}
          />
        </div>

        <div className="campo">
          <label>Activo</label>
          <input
            type="checkbox"
            placeholder="Activar User"
            name="active"
            onChange={actualizarState}
            checked={cliente.active}
          />
        </div>

        <div className="enviar">
          <input
            type="submit"
            className="btn btn-azul"
            value="Guardar Cambios"
            disabled={validarCliente()}
          />
        </div>
      </form>
    </Fragment>
  );
}

// HOC, es una función que toma un componente y retorna un nuevo componente
export default withRouter(EditarCliente);
