import React, { useContext } from "react";
import getUserData from '../../utils/getDataUser';
import { useEffect, useState } from "react";


import { CRMContext } from "../../context/CRMContext";
import { withRouter } from "react-router-dom";

const Header = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  const [token, guardarToken] = useState('');

  const cerrarSesion = () => {
    // auth.auth = false y el token se remueve
    guardarAuth({
      token: "",
      auth: false,
    });

    localStorage.setItem("token", "");
    //localStorage.removeItem("token");
    // redireccionar
    props.history.push("/iniciar-sesion");
  };
  useEffect(() => {
    (localStorage.getItem('token') === null) && localStorage.setItem("token", "")
    guardarToken(getUserData()
    )
  }, [auth]);
  return (
    <header className="barra">
      <div className="contenedor">
        <div className="contenido-barra">

          {auth.auth ? (
            <>
              <label className="h2white">Bienvenido {(token.first_name)}</label>

              {token.id_rol === 2 && <label className="h2white">Tu rol es Encuestador</label>}
              {token.id_rol === 3 && <label className="h2white">Tu rol es Adm.Operativo</label>}
              {token.id_rol === 4 && <label className="h2white">Tu rol es Analista</label>}
              {token.id_rol === 5 && <label className="h2white">Tu rol es Coordinador</label>}
              {token.id_rol === 6 && <label className="h2white">Tu rol es SubCoordinador</label>}






              <button
                type="button"
                className="btn btn-celesteOscuro"
                onClick={cerrarSesion}
              >
                <i className="far fa-times-circle"></i>

                Cerrar Sesión
              </button>
            </>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default withRouter(Header);
