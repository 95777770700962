import React, { useState, useContext } from "react";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";

// Context
import { CRMContext } from "../../context/CRMContext";

function Login(props) {
  // Auth y token
  const [auth, guardarAuth] = useContext(CRMContext);

  // State con los datos del formulario
  const [credenciales, guardarCredenciales] = useState({});

  // iniciar sesión en el servidor
  const iniciarSesion = async (e) => {
    e.preventDefault();

    // autenticar al usuario

    try {
      const respuesta = await clienteAxios.post(
        "/api/login_conbycript",
        credenciales
      );

      // extraer el token y colocarlo en localstorage
      const { token } = respuesta.data;
      localStorage.setItem("token", token);

      // colocarlo en el state
      guardarAuth({
        token,
        auth: true,
      });

      // alerta
      Swal.fire("Login Correcto", "Has iniciado Sesión", "success");
      // redireccionar
      props.history.push("/");
    } catch (error) {
      //console.log(error);
      Swal.fire({
        type: "error",
        title: "Hubo un error",
        text: error.response.data.mensaje,
      });
    }
  };

  // almacenar lo que el usuario escribe en el state
  const leerDatos = (e) => {
    guardarCredenciales({
      ...credenciales,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="login">

      <div className="contenedor-formulario">
        <form onSubmit={iniciarSesion}>
          <div className="campo">
            <label >Email: </label>
            <input
              type="text"
              name="email"
              placeholder="Ingresar Email"
              autocomplete="off"
              required
              onChange={leerDatos}
            />
          </div>

          <div className="campo">
            <label>Contraseña: </label>
            <input
              type="password"
              name="passwd"
              autocomplete="off"
              placeholder="Ingresar Contraseña"
              required
              onChange={leerDatos}
            />
          </div>

          <input
            type="submit"
            value="Iniciar Sesión"
            className="btn btn-celesteOscuro btn-block"
          />
        </form>
      </div>
    </div>
  );
}

export default withRouter(Login);
