import React, { useEffect, useState, useContext, Fragment } from "react";

// importar cliente axios
import clienteAxios from "../../config/axios";
import Cliente from "./Cliente";
import Spinner from "../layout/Spinner";
import { Link, withRouter } from "react-router-dom";

// import el Context
import { CRMContext } from "../../context/CRMContext";
import { UsuariosContext } from "../../context/ListaUsuariosContext";
import Pagination from "./Pagination";
import Posts from "./Posts";


function Clientes(props) {

  const [clientes, guardarClientes] = useState([]);
  const [contador, guardarContador] = useState(0);

  //const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [search, setSearch] = useState("")
  const [users, setUsers] = useState();

  // utilizar valores del context
  const [auth, guardarAuth] = useContext(CRMContext);
  const { usuarios } = useContext(UsuariosContext);
  //const { guardarUsuarios } = useContext(UsuariosContext);

  // use effect es similar a componentdidmount y willmount
  useEffect(() => {
    if (auth.token !== "") {
      // Query a la API
      //consultarAPI();


    } else {
      props.history.push("/iniciar-sesion");
    }
  }, []);

  // Si el state esta como false
  if (!auth.auth) {
    props.history.push("/iniciar-sesion");
  }
  //metodo de filtrado 1 
  let results = []
  if (!search) {
    results = usuarios
  } else {
    results = usuarios.filter((dato) =>
      dato.last_name.toLowerCase().includes(search.toLocaleLowerCase())
    )

  }

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  var currentPosts = results.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);
  //función de búsqueda
  const searcher = (e) => {
    setSearch(e.target.value)
    setCurrentPage(1);

  }
  return (
    <Fragment>
      <h2>Usuarios</h2>
      <Link to={"/clientes/nuevo"} className="btn btn-celeste nvo-cliente">
        <i className="fas fa-plus-circle"></i>
        agregar usuario
      </Link>


      <div className="busqueda">
        <div class="info-cliente">
          <p>
            <label className="label2">Busqueda por Apellido:</label>
          </p>
          <p>
            <input value={search} onChange={searcher} type="text" placeholder='Ingrese Apellido a Buscar' className='busquedainput' />
          </p>
        </div>
      </div>

      <Posts usuarios={currentPosts} loading={loading} />

      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={results.length}
        paginate={paginate}
      />
    </Fragment>
  );
}
export default withRouter(Clientes);
