import React, { createContext, useState, useContext, useEffect } from "react";
import { CRMContext } from "./CRMContext";

import clienteAxios from "../config/axios";


//const UsuariosContext = React.createContext([{}, () => { }]);
const UsuariosContext = React.createContext([{}, () => { }]);

const UsuariosProvider = (props) => {
    const [auth, guardarAuth] = useContext(CRMContext);
    const [paginaactual, guardarPaginaActual] = useState(1);
    const [totalpaginas, guardarTotalPaginas] = useState(1);

    const [usuarios, guardarUsuarios] = useState([]);
    const [busqueda, buscarRecetas] = useState({
        nombre: "",
        categoria: "",
        veralcohol: "",
    });
    const [consultar, guardarConsultar] = useState(false);

    const { nombre, categoria, veralcohol } = busqueda;
    //agrego nuevo

    //fin nuevo
    useEffect(() => {
        if (auth.token !== "") {

            console.log("llamo a la api")
            const consultarAPI = async () => {
                try {
                    //const clientesConsulta = await clienteAxios.get("/usuarios?desde=" + contador || 0, {
                    const clientesConsulta = await clienteAxios.get("/usuarios", {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    });

                    // colocar el resultado en el state
                    guardarUsuarios(clientesConsulta.data.rows);
                    const calcularTotalPaginas = Math.ceil(
                        clientesConsulta.data.rows.length / 10
                    );
                    guardarTotalPaginas(calcularTotalPaginas);

                    //console.log("================");
                    // console.log(clientesConsulta.data);
                } catch (error) {
                    // Error con authorizacion
                    //console.log(error.response);
                    if ((error.response.status = 500)) {
                        props.history.push("/iniciar-sesion");
                    }
                }
            };

            //console.log(clientesConsulta.data.rows.length);
            consultarAPI()
            // Query a la API
            //consultarAPI();
        }

    }, [paginaactual, auth]);

    return (
        <UsuariosContext.Provider
            value={{
                usuarios,
                guardarUsuarios,
                buscarRecetas,
                guardarConsultar,
                paginaactual,
                guardarPaginaActual,
                totalpaginas,
            }}
        >
            {props.children}
        </UsuariosContext.Provider>
    );
};
export { UsuariosContext, UsuariosProvider };
