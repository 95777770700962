

import React, { useEffect, useState, useContext } from "react";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import { Link } from "react-router-dom";
import { ExportToExcel } from './ExportToExcel'

const Personal = (props) => {
    const [auth] = useContext(CRMContext);
    const [usuarios, guardarUsuarios] = useState([])

    if (auth.token === "")
        props.history.push("/iniciar-sesion");

    //   props.history.push("/iniciar-sesion");

    /*  const goBack = () => {
         props.history.goBack()
     } */
    useEffect(() => {

        const consultarAPI = async () => {

            const clienteConsulta = await clienteAxios.get(
                `/usuariosconroles/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            // console.log(clienteConsulta.data);
            guardarUsuarios(clienteConsulta.data.rows);

        };
        consultarAPI()


    }, [])
    //console.log("Se carga el componente");
    return (
        <div className="container mt-5" align="center">
            <h1 className="animate__animated  animate__flash celesteOscuro">Gestión de usuarios</h1>
            <div className="row">
                <ExportToExcel apiData={usuarios} fileName="usuarios" />

                <div className="col-md-12">

                    <table id="tableuser" className="table animate__animated  animate__fadeInLeft ">
                        <thead className="">
                            <tr>
                                <th scope="col">Apellido</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Usuario</th>
                                <th scope="col">Teléfono</th>
                                <th scope="col">Activo</th>
                                <th scope="col">Código Partido</th>
                                <th scope="col">Rol</th>
                            </tr>
                        </thead>
                        <tbody>

                            {usuarios.map(item => (

                                <tr key={item.email}>

                                    <td className="azul">{item.apellido}</td>
                                    <td className="azul">{item.nombre}</td>
                                    <td className="azul">{item.usuario}</td>
                                    <td className="azul">{item.phone}</td>
                                    {item.activo === true ? (
                                        <td className="azul">Activo</td>
                                    ) : (
                                        <td className="rojo">Inactivo</td>
                                    )}

                                    <td className="azul">{item.codigo_partido}</td>
                                    <td className="azul">{item.rol}</td>
                                    {/* 
                                    {item.id_rol !== undefined && (

                                        <>
                                            {item.id_rol === 2 && <td className="azul">Encuestador</td>}
                                            {item.id_rol === 3 && <td className="azul">Adm.Operativo</td>}
                                            {item.id_rol === 4 && <td className="azul">Analista</td>}
                                            {item.id_rol === 5 && <td className="azul">Coordinador</td>}
                                            {item.id_rol === 6 && <td className="azul">SubCoordinador</td>}
                                        </>
                                    )
                                    } */}



                                    {/* <td><img src={`${process.env.PUBLIC_URL}/imagenes/${item.img}`} alt={item.nombre} width="30px" className="img-fluid" /></td> */}
                                </tr>

                            ))}

                        </tbody>

                    </table>

                </div>

            </div>
            <Link to={{ pathname: `/` }} className="btn btn-rojo ml-40  redondo">volver</Link>



        </div >
    );
}

export default Personal;