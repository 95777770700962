import React from 'react';
import Cliente from './Cliente';

const Posts = ({ usuarios, loading }) => {
    if (loading) {
        return <h2>Loading...</h2>;
    }

    return (
        <ul className="listado-clientes">
            {usuarios.map((usuario) => (
                <Cliente key={usuario.id_user} cliente={usuario} />
            ))}
        </ul>
    );
};

export default Posts;